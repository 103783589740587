.container {
  padding: 20px;
}

.tagline {
  opacity: 0.1;
  margin: -20px 0 30px 20px;
  font-size: 20px;
}

.block {
  margin-bottom: 40px;
}

h3 {
  margin-bottom: 10px;
}

.do-list {
  margin-left: 10px;
}

.do-list li {
  line-height: 30px;
}

.found-list {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
}

.found-list a {
  opacity: 0.4;
  transition: opacity 0.5s;
}

.found-list a:hover {
  opacity: 1;
}

.found-list svg {
  width: 50px;
  height: auto;
  fill: #e8ddcb;
}

.content {
  max-width: 960px;
}

@media screen and (min-width: 500px) {
  h1 {
    font-size: 50px;
  }

  .tagline {
    font-size: 30px;
    margin-top: -35px;
  }

  h3 {
    font-size: 25px;
  }

  .container {
    margin: 0 auto;
    max-width: 600px;
  }

  .content {
    display: flex;
    justify-content: stretch;
  }

  .block {
    width: 50%;
    padding-right: 30px;
    margin: 0;
  }

  .block2 {
    width: 50%;
  }
}
